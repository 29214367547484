import React, {useEffect, useRef, useState} from "react";
import {ScheduleMaintenanceProps} from "../CFAdminMaintenanceSchedule/types";
import VehicleConfigService from "../../services/VehicleConfigService";
import SysMaintainenceService from "../../services/SysMaintainenceService";
import {Formik} from "formik";
import Stepper from "react-stepper-horizontal";
import {FVPSelect, FVPTextInputWithSize} from "../../components/formField";
import CFAdminDrivingConditions from "../CFAdminMaintenanceSchedule/CFAdminDrivingConditions";
import CFAdminVehicles from "../CFAdminMaintenanceSchedule/CFAdminVehicles";
import CFAdminIntervals from "../CFAdminMaintenanceSchedule/CFAdminIntervals";
import CFAdminModal from "../../components/CFAdminModal";
import CFAdminMaintenanceScheduleForm from "../CFAdminMaintenanceSchedule/CFAdminMaintenanceScheduleForm";
import CFAdminNotification from "../../components/CFAdminNotification";
import * as Yup from "yup";

type OptionType = { description: string; code?: string };

const schema = Yup.object().shape({
    make: Yup.string()
        .required("Make is a required field"),
    model: Yup.string()
        .required("Model is a required field"),
    year: Yup.string()
        .required("Year is a required field"),
});

function CFAdminFootNoteConfig() {
    const childCompRef = useRef<any>();
    const [activeStep, setActiveStep] = useState(0);
    const [nextStep, setNextStep] = useState(0);
    const [makesOptions, setMakesOptions] = useState<OptionType[]>([]);
    const [modelsOptions, setModelsOptions] = useState<OptionType[]>([]);
    const [yearsOptions, setYearsOptions] = useState<OptionType[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [vin, setVin] = useState('');
    const [vehicleDetails, setVehicleDetails] = useState<ScheduleMaintenanceProps>({
        make: '',
        model: '',
        year: '',
        vin: '',
        drivingConditionId: '',
        modelYearId: '',
        isPublish: '',
        vehicleDrivingConditionId:'',
        scheduleCode: '',
        countryLanguageId: 1
    });
    const [drivingConditions, setDrivingConditions] = useState<any[]>([]);
    const [vehicleConfigurations, setVehicleConfigurations] = useState<any[]>([]);
    const [scheduleMileages, setScheduleMileages] = useState<any[]>([]);
    const [scheduleMaintenance, setScheduleMaintenance] = useState<any[]>([]);
    const [countryLanguages, setCountryLanguages] = useState<OptionType[]>([]);
    const [defaultLanguage, setDefaultLanguage] = useState<any>(1);
    const [vinDetails, setVinDetails] = useState<any>(null);
    const [makeSelectValue, setMakeSelectValue] = useState('');
    const [modelSelectValue, setModelSelectValue] = useState('');
    const [yearSelectValue, setYearSelectValue] = useState('');
    const [isVinDecoded,setIsVinDecoded] = useState(false);
    const [isVinInvalid,setIsVinInvalid] = useState(false);

    const steps = [
        {title: 'Vehicle', onClick: () => setActiveStep(0)},
        {title: 'Driving Conditions', onClick: () => setActiveStep(1)},
        {title: 'vehicle Configurations', onClick: () => setActiveStep(2)},
        {title: 'Schedule', onClick: () => setActiveStep(3)},
    ];

    const getVehicleMakes = async () => {
        VehicleConfigService.getVehicleMakes()
            .then(response => {

                const responseModel = response.data?.response;
                let makeOptions : OptionType[] = [];
                if (responseModel) {
                    responseModel.forEach((element: any) => {
                        let data : OptionType = { description: element.description,
                            code: element.id };
                        makeOptions.push(data);
                    });
                    setMakesOptions(makeOptions);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getVehicleModels = async (makeId : any, yearId: any) => {

        const selectedMakeOption = makesOptions.find(option => String(option.code) === makeId);
        const selectedYearOption = yearsOptions.find(option => String(option.code) === yearId);

        VehicleConfigService.getVehicleModels(selectedMakeOption?.description ?? '', selectedYearOption?.description ?? '')
            .then(response => {

                const responseModel = response.data?.response;
                let modelOptions : OptionType[] = [];
                if (responseModel) {
                    responseModel.forEach((element: any) => {

                        let data : OptionType = { description: element.description,
                            code: element.id };
                        modelOptions.push(data);
                    });
                    setModelsOptions(modelOptions);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getVehicleYears = async () => {
        VehicleConfigService.getVehicleYears()
            .then(response => {

                const responseModel = response.data?.response;
                let yearOptions : OptionType[] = [];
                if (responseModel) {
                    responseModel.forEach((element: any) => {

                        let data : OptionType = { description: element.description,
                            code: element.id };
                        yearOptions.push(data);
                    });
                    setYearsOptions(yearOptions);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const onChangeMake = async (e: any) => {

        vehicleDetails.make = e.target.value;
        setMakeSelectValue(e.target.value);
        if(vehicleDetails.make !== '' && vehicleDetails.year !== ''){
            setModelsOptions([]);
            getVehicleModels(vehicleDetails.make, vehicleDetails.year);
        }
    };

    const onChangeYear = async (e: any) => {

        vehicleDetails.year = e.target.value;
        setYearSelectValue(e.target.value);
        if(vehicleDetails.make !== '' && vehicleDetails.year !== ''){
            setModelsOptions([]);
            getVehicleModels(vehicleDetails.make, vehicleDetails.year);
        }
    };

    const onChangeModel = async (e: any) => {

        vehicleDetails.model = e.target.value;
        setModelSelectValue(e.target.value);
    };

    const onChangeLanguage = async (e: any) => {
        setDefaultLanguage(e.target.value);
        vehicleDetails.countryLanguageId = e.target.value;
    };


    useEffect(() => {
        getSectionComponent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, nextStep]);

    function getSectionComponent() {

        switch (activeStep) {
            case 0:
                getVehicleMakes();
                getVehicleYears();
                getAllCountryLanguages();
                if (nextStep === 1) {
                    handleStepperNext(1);
                }
                break;
            case 1:
                if (nextStep === 2) {
                    handleStepperNext(2);
                } else if (nextStep === 0) {
                    handleStepperNext(0);
                }
                break;
            case 2:
                if (nextStep === 3) {
                    handleStepperNext(3);
                } else if (nextStep === 1) {
                    handleStepperNext(1);
                }
                break;
            case 3:
                if (nextStep === 2) {
                    handleStepperNext(2);
                }
                break;
            default:
                console.error("Unrecognized step");
        }
    }

    const getAllCountryLanguages = async () => {
        SysMaintainenceService.getAllCountryLanguages()
            .then(response => {

                const countriesLanguages = response.data?.countriesLanguagesResponseModel;
                let countriesLanguageOptions : OptionType[] = [];
                if (countriesLanguages) {
                    countriesLanguages.forEach((subRows: any) => {

                        subRows?.subRows.forEach((element: any) => {
                            let data : OptionType = { description: subRows.isoCode+"-"+element.isoCode,
                                code: element.countryLanguageId };
                            countriesLanguageOptions.push(data);
                        });
                    });
                }
                setDefaultLanguage(1);
                setCountryLanguages(countriesLanguageOptions);
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getDrivingConditions = async () => {

        const request = {
            "makeId" : +vehicleDetails.make,
            "modelId" : +vehicleDetails.model,
            "yearId" : +vehicleDetails.year,
            "attributeValueIds" : []
        };
        // Check if vinDetails.attributeValueIds exists and is not empty
        if (vinDetails && vinDetails.attributeValueIds && vinDetails.attributeValueIds.length > 0) {
            // If so, add attributeValueIds to the request object
            request.attributeValueIds = vinDetails.attributeValueIds;
        }

        VehicleConfigService.getScheduleMaintenanceDrivingConditions(request)
            .then(response => {
                vehicleDetails.modelYearId = response.data?.modelYearId;
                const drivingConditions = response.data?.drivingConditions;
                if (drivingConditions) {
                    setDrivingConditions(drivingConditions);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getScheduleMaintenanceVehicles = async () => {

        const request = {
            "modelYearId" : vehicleDetails.modelYearId,
            "drivingConditionId" : vehicleDetails.drivingConditionId,
            "isPublish" : true,
            "attributeValueIds" : []
        };
        // Check if vinDetails.attributeValueIds exists and is not empty
        if (vinDetails && vinDetails.attributeValueIds && vinDetails.attributeValueIds.length > 0) {
            // If so, add attributeValueIds to the request object
            request.attributeValueIds = vinDetails.attributeValueIds;
        }
        VehicleConfigService.getScheduleMaintenanceVehicles(request)
            .then(response => {

                const vehicleConfigurationResponse = response.data?.vehicleConfigurationResponse;
                if (vehicleConfigurationResponse) {
                    setVehicleConfigurations(vehicleConfigurationResponse);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getScheduleMaintenanceIntervals = async () => {

        VehicleConfigService.getScheduleMaintenanceIntervals(vehicleDetails.vehicleDrivingConditionId)
            .then(response => {

                const scheduleMileages = response.data?.scheduleMileages;
                if (scheduleMileages) {
                    setScheduleMileages(scheduleMileages);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getVehicleScheduleMaintenance = async () => {

        VehicleConfigService.getVehicleScheduleMaintenance(vehicleDetails.vehicleDrivingConditionId, vehicleDetails.scheduleCode, vehicleDetails.countryLanguageId)
            .then(response => {

                const scheduleMaintenance = response.data;
                if (scheduleMaintenance) {
                    setScheduleMaintenance(scheduleMaintenance);
                    toggleModal();
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const getDecodeVin = async () => {

        VehicleConfigService.getDecodeVin(vehicleDetails.vin)
            .then(response => {

                const decodeVinData = response.data;
                if (decodeVinData) {
                    setVinDetails(decodeVinData);
                    setMakeSelectValue(decodeVinData.makeId);
                    setYearSelectValue(decodeVinData.yearId);
                    setVehicleDetails({
                        ...vehicleDetails,
                        make: String(decodeVinData.makeId),
                        model: String(decodeVinData.modelId),
                        year: String(decodeVinData.yearId),
                    });
                    setModelsOptions([]);
                    getVehicleModels(String(decodeVinData.makeId), String(decodeVinData.yearId));
                    setModelSelectValue(decodeVinData.modelId);
                    setIsVinDecoded(true);
                }
            })
            .catch(e => {
                setIsVinInvalid(true);
                confirmErrorMessage("Please enter Valid VIN");
                console.error(e);
            });
    };

    const handleSubmit = (values: any) => {
        toggleModal();
    };

    const switchToVehConfig = () => {
        getScheduleMaintenanceVehicles();
        setNextStep(activeStep + 1);
    }

    const switchToIntervals = () => {
        getScheduleMaintenanceIntervals();
        setNextStep(activeStep + 1);
    }

    const showSchedule = () => {
        setVehicleDetails({
            ...vehicleDetails,
        });
        getVehicleScheduleMaintenance();
    }

    const decodeVin = () => {
        if(vehicleDetails.vin === ''){
            confirmErrorMessage("Please enter VIN value");
            return;
        }
        getDecodeVin();
    }

    const resetForm = () => {
        setVin('');
        setVehicleDetails({
            make: '',
            model: '',
            year: '',
            vin: '',
            drivingConditionId: '',
            modelYearId: '',
            isPublish: '',
            vehicleDrivingConditionId: '',
            scheduleCode: '',
            countryLanguageId: 1
        });
        setVinDetails(null);
        setMakeSelectValue('');
        setYearSelectValue('');
        setModelsOptions([]);
        setModelSelectValue('');
        setActiveStep(0);
        setNextStep(0);
    };

    const toggleModal = () => {
        if(vehicleDetails.scheduleCode === '' || vehicleDetails.countryLanguageId === null || vehicleDetails.vehicleDrivingConditionId === ''){
            confirmErrorMessage("Please Enter all required fields");
            return;
        }
        setIsOpen(!isOpen);
    }

    const confirmErrorMessage = async (value: any) => {
        childCompRef.current.failureMessage(value);
    };

    const onChangeVin = async (e: any) => {
        vehicleDetails.vin = e.target.value;
        setVin(e.target.value);
        setIsVinInvalid(false);
        setIsVinDecoded(false);

    };

    const handleStepperNext = (nextStep: number) => {
        setActiveStep(nextStep);
    };
    const handleNextClick = (values: any) => {

        if(activeStep === 0){

            if(isVinInvalid) {
                confirmErrorMessage("Please enter valid VIN to proceed further");
                return;
            }
            if(!isVinDecoded && vin !== ''){
                confirmErrorMessage("Please click on Decode button to proceed further");
                return;
            }
            if( (vehicleDetails.make === '' || vehicleDetails.year === '' || vehicleDetails.model === '')
                && (vinDetails === undefined || vinDetails === null)){
                confirmErrorMessage("Please Enter VIN or Select Make, Year and Model.");
                return;
            }

            getDrivingConditions();
        }

        setNextStep(activeStep + 1);
    };

    const handlePreviousClick = () => {
        setNextStep(activeStep - 1);
    };

    return (
        <>
            <div className="config-component">
                <h2 className="fomo-type--title">
                    FootNote Assignment
                </h2>
                <Formik
                    validationSchema={schema}
                    initialValues={{ make: '', modelDesc: ''  }}
                    onSubmit={handleSubmit}
                >
                    {({values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, dirty}) => (
                        <div className="fvp-form">
                            <div className="modal-top-div">
                                <div className="modal-body">
                                    <div className="stepper">
                                        <Stepper steps={steps} activeStep={activeStep}/>
                                        <div className="active-step">
                                            <>
                                                {activeStep === 0 &&
                                                    <>
                                                        <div className="ext1">
                                                            Please Select Make, Year and Model.
                                                            <>
                                                                <div className="container">
                                                                    <div className="box">
                                                                        <div className="box-row">
                                                                           <div className="box-cell box2">
                                                                                <div className="fvp-form__subgroup">
                                                                                    <FVPSelect
                                                                                        style={{width: "900px"}}
                                                                                        label="Make:"
                                                                                        name="makesOptions"
                                                                                        data-testid="make"
                                                                                        value={makeSelectValue}
                                                                                        onChange={onChangeMake}
                                                                                        options={makesOptions}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                <div className="fvp-form__subgroup">
                                                                                    <FVPSelect
                                                                                        style={{width: "900px"}}
                                                                                        label="Year:"
                                                                                        name="yearsOptions"
                                                                                        value={yearSelectValue}
                                                                                        data-testid="year"
                                                                                        onChange={onChangeYear}
                                                                                        options={yearsOptions}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                <div className="fvp-form__subgroup">
                                                                                    <FVPSelect
                                                                                        style={{width: "900px"}}
                                                                                        label="Model:"
                                                                                        name="modelsOptions"
                                                                                        data-testid="model"
                                                                                        value={modelSelectValue}
                                                                                        onChange={onChangeModel}
                                                                                        options={modelsOptions}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        </div>
                                                    </>
                                                }
                                                {activeStep === 1 && (
                                                    <>
                                                        <div className="ext1">
                                                            Please Select Driving Condition from the below options.
                                                            {drivingConditions.length > 0 && <CFAdminDrivingConditions
                                                                vehicleDetails={vehicleDetails}
                                                                switchToVehConfig={switchToVehConfig}
                                                                dataValues={drivingConditions} />
                                                            }
                                                        </div>
                                                    </>
                                                )}
                                                {activeStep === 2 && (
                                                    <>
                                                        <div className="ext1">
                                                            Please Select a Vehicle from the below options.
                                                            {vehicleConfigurations.length > 0 && <CFAdminVehicles
                                                                vehicleDetails={vehicleDetails}
                                                                switchToIntervals={switchToIntervals}
                                                                dataValues={vehicleConfigurations} />
                                                            }
                                                        </div>
                                                    </>
                                                )}
                                                {activeStep === 3 && (
                                                    <>
                                                        <div style={{width: '70%', margin: '10px', display: 'table',paddingLeft:'20%'}}>
                                                            <div style={{display: 'table-row'}}>
                                                                <div style={{width: '30px', display: 'table-cell', paddingRight:'40%'}}>
                                                                    <div className="fvp-form__subgroup">
                                                                        <FVPSelect
                                                                            style={{width: "200px"}}
                                                                            label="Language:"
                                                                            name="countryLanguagesOptions"
                                                                            data-testid="countryLanguages"
                                                                            value={defaultLanguage}
                                                                            onChange={onChangeLanguage}
                                                                            options={countryLanguages}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div style={{
                                                                    width: '50px',
                                                                    display: 'table-cell',
                                                                }}>
                                                                    {scheduleMileages.length > 0 && <CFAdminIntervals
                                                                        vehicleDetails={vehicleDetails}
                                                                        showSchedule={showSchedule}
                                                                        dataValues={scheduleMileages}/>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        </div>
                                        <div className="modal-footer button-frame">
                                            <button data-testid="step-cancel" className="fvp-button--outline fvp-button"
                                                    type="submit"
                                                    onClick={resetForm}>
                                                <i className="fvp-left-chevron"/>Cancel
                                            </button>
                                            <div className="right-align">
                                                {(activeStep !== 0) && (
                                                    <button data-testid="step-prev"
                                                            className="fvp-button button-right-align" type="button"
                                                            onClick={handlePreviousClick}>
                                                        <i className="fvp-left-chevron"/> Previous
                                                    </button>
                                                )}
                                                {activeStep === 0 && (
                                                    <button data-testid="step-next"
                                                            className="fvp-button button-right-align" type="button"
                                                            onClick={() => handleNextClick(values)}>
                                                        Next <i className="fvp-right-chevron"/>
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Formik>
                <CFAdminModal
                    isOpen={isOpen}
                    toggle={toggleModal}
                    isAdd={true}
                    title={'Scheduled Maintenance'}
                >
                    <CFAdminMaintenanceScheduleForm
                        scheduleMaintenance={scheduleMaintenance}
                        vehicleDetails={vehicleDetails}
                    />
                </CFAdminModal>
                <CFAdminNotification ref={childCompRef}/>
            </div>
        </>
    );
}

export default CFAdminFootNoteConfig;