import axios from "axios";
import AuthService from "../services/AuthService";
import {clearTokens, retrieveUrl, setUserTokens} from "./auth-validation";

const url = window.location.href;

let BASE_URL: string | undefined = "";
if(url.includes("www."))
  BASE_URL = process.env.REACT_APP_BASE_URL_PROD;
else if(url.includes("wwwqa."))
  BASE_URL = process.env.REACT_APP_BASE_URL_QA
else
  BASE_URL = process.env.REACT_APP_BASE_URL_DEV

const http = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-type": "application/json"
  }
});

// Show the PleaseWait component
const showPleaseWait = () => {
    const pleaseWaitElement = document.getElementById('please-wait');
    if (pleaseWaitElement) {
        pleaseWaitElement.style.display = 'block';
        pleaseWaitElement.style.zIndex = '9999';
    }
    document.body.style.pointerEvents = 'none'; // Disable mouse clicks
};

// Hide the PleaseWait component
const hidePleaseWait = () => {
    const pleaseWaitElement = document.getElementById('please-wait');
    if (pleaseWaitElement) {
        pleaseWaitElement.style.display = 'none';
    }
    document.body.style.pointerEvents = 'auto'; // Enable mouse clicks
};

//request interceptor
http.interceptors.request.use((config) => {
      document.body.style.cursor = 'wait';
      showPleaseWait();
      return config;
    }
);

const attemptRetry = async (error: any, originalRequest: any) => {
    originalRequest._retry = true;
    console.log("Attempting to refresh token");
    try {
        const response = await AuthService.getRefreshToken();
        if (response.data.errorCode != null) {
            console.error("No error code received, clearing tokens and redirecting to login");
            return clearTokens().then(() => {
                retrieveUrl();
            });
        }

        const awsmodtoken = response.headers['X-Access-Token'];
        const rwsmodtoken = response.headers['X-Refresh-Token'];
        await setUserTokens(awsmodtoken, rwsmodtoken);

        originalRequest.headers['Authorization'] = 'Bearer ' + awsmodtoken;
        return http(originalRequest);
    } catch (e) {
        console.error("Error refreshing token, clearing tokens and redirecting to login");
        return clearTokens().then(() => {
            retrieveUrl();
        });
    }
};
// Response interceptor
http.interceptors.response.use(
    response => {
        document.body.style.cursor = 'default';
        hidePleaseWait();
        return response;
    },
    async error => {
        document.body.style.cursor = 'default';
        hidePleaseWait();
        const originalRequest = error.config;
        console.error("interceptor error received");
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            return attemptRetry(error, originalRequest);
        } else if (error.response && error.response.status === 403) {
            console.error("Access forbidden, clearing tokens and redirecting to login");
        } else {
            console.warn("Unexpected error, returning error");
        }
        return Promise.reject(error);
    }
);

export default http;