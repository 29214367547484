import React, {useEffect, useRef, useState} from "react";
import './CFAdminManageMappingRule.scss';
import CFAdminDataTable from "../../components/CFAdminDataTable";
import CFAdminModal from "../../components/CFAdminModal";
import ViewIcon from "../../assets/images/view-icon.svg";
import {DeleteButton, EditButton} from "../../components/CFAdminDataTable/ActionsButtonComponents";
import VinImportService from "../../services/VinImportService";
import CFAdminConfirmDialog from "../../components/CFAdminConfirmDialog";
import CFAdminNotification from "../../components/CFAdminNotification";
import CFAdminReconcileAttribute from "./CFAdminReconcileAttribute";
import VehicleConfigService from "../../services/VehicleConfigService";

export interface MultiAttributeSelectOption {
    readonly value: string;
    readonly label: string;
}

export interface MultiAttributeSelectOption {
    readonly label: string;
}
function CFAdminManangeMappingRule({ initialActiveStep = 0, initialStep = 0}) {

    const childCompRef = useRef<any>();
    const [ruleGroups, setRuleGroups] = useState([]);
    const [ruleGroupQueries, setRuleGroupQueries] = useState([]);
    const [ruleDescSelected, setRuleDescSelected] = useState("");
    const [fordFlag, setFordFlag] = useState(false);
    const [ruleSelectedCount, setRuleSelectedCount] = useState(0);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isStepperOpen, setStepperOpen] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [isEdit,setIsEdit] = useState(false);
    const [ruleId, setRuleId] = useState(false);
    const [activeStep, setActiveStep] = useState(initialActiveStep);
    const [nextStep] = useState(initialStep);
    const [attributesOptions] = useState<MultiAttributeSelectOption[]>([]);
    const [vinMappedAttributes, setVinMappedAttributes] = useState<MultiAttributeSelectOption[]>([]);
    const [vinMappedOriginalSrcAttributes, setVinMappedOriginalSrcAttributes] = useState<MultiAttributeSelectOption[]>([]);
    const [vinMappedSourceAttributes, setVinMappedSourceAttributes] = useState<MultiAttributeSelectOption[]>([]);
    const [vinMappedDstAttributes, setVinMappedDstAttributes] = useState<MultiAttributeSelectOption[]>([]);
    const [ruleSourceAttributeId, setRuleSourceAttributeId] = useState("");
    const [ruleDstAttributeId, setRuleDstAttributeId] = useState("");
    const [ruleSourceAttributeValueDesc, setRuleSourceAttributeValueDesc] = useState("");
    const [ruleDstAttributeValueId, setRuleDstAttributeValueId] = useState("");

    const handleAttributeOptions = (values : any) => {
        console.log("Selected Values: ", values);
    }

    const stepMapping : any = {
        0: [1],
        1: [0, 2],
        2: [1, 3],
        3: [2, 4],
        4: [3, 5],
        5: [4]
    };

    function getSectionComponent() {
        const nextSteps = stepMapping[activeStep];

        if (nextSteps.includes(nextStep)) {
            handleStepperNext(nextStep);
        } else {
            console.error(stepMapping+" :: Unrecognized step :: "+activeStep);
        }
    }

    const handleStepperNext = (nextStep: number) => {
        setActiveStep(nextStep);
    };

    useEffect(() => {
        getSectionComponent();
        if(activeStep === 0){
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, nextStep]);

    const GenerateConfigButton = ({ handleGenerate, props, titleDesc } : any) => (
        <div>
            <img
                className="hover-cursor"
                title={titleDesc}
                src={ViewIcon}
                data-testid={"rule-query" + props.row.original.fieldK}
                onClick={() => handleRowClick(props.row.original.vinX, props.row.original.sptNonFordF)}
                alt="Xcelvations Logo"
                height="15"
            />
        </div>
    );

    const columnsRuleGroup = [
        {
            Header: "Rule Groups",
            accessor: 'sptNonFordF',
            Cell: ({ row }: any) => (
                <div className="cell-max-row-height">
                    {row.original.vinX} {row.original.sptNonFordF ? '[True]' : '[False]'}
                </div>
            )
        },
        {
            Header: "Actions",
            Cell: (props: any) => {
                return (
                    <GenerateConfigButton handleGenerate={handleGenerate} props={props} titleDesc="View Rule Queries" />
                );
            }
        }
    ];

    const ruleQueryColumns = [
        {
            Header: "Attribute Rules",
            accessor: 'ruleQuery',
            Cell: ({value}: any) => <div style={{ width: '1200px' }}>{value}</div>
        },
        {
            Header: "Actions",
            Cell: (props: any) => {

                return  (
                    <div className="cell-buttons">
                        {renderEditButton(props, handleChange)}
                        {renderDeleteButton(props, handleDelete)}
                    </div>
                )}
        },
    ];


    const renderEditButton = (props: any, handleChange: Function) => {
        return <EditButton {...props} handleChange={() => handleChange(props.row.original.ruleId)}/>
    }

    const renderDeleteButton = (props: any, handleDelete: Function, disabled: boolean = false) => {
        return <DeleteButton {...props} handleDelete={() => handleDelete(props.row.original.ruleId)} disabled={disabled} />;
    };

    const handleDelete = (ruleId: any) => {
        setRuleId(ruleId);
        setIsDelete(true);
    };

    const handleChange = (ruleId: any) => {
        console.log("Edit Config for: ", ruleId)
        setIsEdit(true);
        retrieveSourceDetails();
        setRuleId(ruleId);
        getRuleAttributebByRuleId(ruleId);
    }

    useEffect(() => {

        if( ruleSourceAttributeId !== "" || ruleDstAttributeId !== "") {
            const matchedSourceAttribute = vinMappedOriginalSrcAttributes.find(attribute => attribute.value === ruleSourceAttributeId);
            if (matchedSourceAttribute) {
                setVinMappedSourceAttributes([matchedSourceAttribute]);
            }

            const matchedDstAttribute = vinMappedAttributes.find(attribute => attribute.value === ruleDstAttributeId);
            if (matchedDstAttribute) {
                setVinMappedDstAttributes([matchedDstAttribute]);
            }
        }

    }, [ruleSourceAttributeId, ruleDstAttributeId]);


    const getRuleAttributebByRuleId = async (ruleId: any) => {
        VinImportService.getRuleAttributesById(ruleId)
            .then(response => {
                setRuleSourceAttributeId(response.data.fieldMapKey);
                setRuleDstAttributeId(response.data.destinationAttributeId);
                setRuleSourceAttributeValueDesc(response.data.vendorValue);
                setRuleDstAttributeValueId(response.data.destinationAttributeValueId);
            })
            .catch(e => {
                confirmErrorMessage("Failed to get Latest Rule Queries")
                console.log(e);
            });
    }

    const handleGenerate = (props: any) => {
        console.log("Generate Config for: ", props.row.original)
    }

    const handleRowClick = (ruleDesc: string, fordFlag : boolean) => {
        setIsPopupOpen(true);
        ruleQueriesById(ruleDesc, fordFlag);
        setRuleDescSelected(ruleDesc);
        setFordFlag(fordFlag);
    };

    const ruleQueriesById = async (ruleDesc: any, fordFlag : boolean) => {

        VinImportService.getRuleQueries(ruleDesc, fordFlag)
            .then(response => {
                setRuleGroupQueries(response.data);
                setRuleSelectedCount(response.data.length);
            })
            .catch(e => {
                confirmErrorMessage("Failed to get Latest Rule Queries")
                console.log(e);
            });
    };


    const confirmSuccessMessage = async (value: any) => {
        childCompRef.current.successMessage(value);
    }
    const confirmErrorMessage = async (value: any) => {
        childCompRef.current.failureMessage(value);
    };

    const ruleGroupIndex = async () => {
        VinImportService.getRuleGroupIndex()
            .then(response => {
                setRuleGroups(response.data);

                let attributes: MultiAttributeSelectOption[] = [];
                response.data.forEach((element: any) => {
                    let option: MultiAttributeSelectOption = {
                        value: element.fieldK!,
                        label: 'v'+element.vinX!
                    };
                    attributes.push(option);
                });

                setVinMappedOriginalSrcAttributes(attributes);

            })
            .catch(e => {
                confirmErrorMessage("Failed to get Latest Rule Group Index")
                console.log(e);
            });
    };


    useEffect(() => {
        ruleGroupIndex();
    }, []);


    const handleColumnClick = async () => {
        await retrieveSourceDetails();
        setStepperOpen(true);
    };


    const retrieveSourceDetails = async () => {
        VehicleConfigService.getVinMappedAttributes()
            .then(response => {
                let responseModel = response.data.attributes;
                if (responseModel) {
                    let attributes: MultiAttributeSelectOption[] = [];
                    responseModel.forEach((element: any) => {
                        let option: MultiAttributeSelectOption = {
                            value: element.attributeId!,
                            label: element.attributeName!
                        };
                        attributes.push(option);
                    });
                    setVinMappedAttributes(attributes);
                }
            })
            .catch(e => {
                confirmErrorMessage("Failed to get retrieveDestinationDetails")
                console.log(e);
            });
    };

    const invokeRuleDelete = (ruleId : any) => {

        VinImportService.deleteRuleById(ruleId)
        .then(() => {
            confirmSuccessMessage(`Deleted rule successfully.`);
            setIsDelete(false);
            ruleQueriesById(ruleDescSelected, fordFlag);
        })
        .catch(e => {
            confirmErrorMessage("Error while deleting Model!");
        });
    }

    const toggleModal = () => {
        setStepperOpen(!isStepperOpen);
    }

    const toggleEditModal = () => {
        setIsEdit(!isEdit);
        setRuleSourceAttributeId("");
        setRuleDstAttributeId("");
    }


    return (
        <>
            <div className="vinimport-component">
                <h2 className="fomo-type--title">Manage Mapping Rules</h2>
                <div>
                    <div>
                        <div>
                            <div>
                                <div className="ext5">
                                    <div data-testid="ManageRuleDesc"> You may view a group of rules, create a new rule
                                        or apply all rules to the current data. <br/>
                                        The mapping rules are grouped by their mapped attributes. Click on a rule group
                                        link to view the rules for that attributes.<br/>
                                        <b>Note:</b> Rules with multiple mapped attributes will appear in each of the
                                        attribute groups.
                                    </div>

                                    <CFAdminDataTable
                                        columns={columnsRuleGroup}
                                        data-testid="create-rule"
                                        data={ruleGroups}
                                        textModal={'Create New Rule'}
                                        showAdd={true}
                                        pageSize={1000}
                                        toggleModal={() => handleColumnClick()}
                                    />

                                    <CFAdminModal
                                        isOpen={isPopupOpen}
                                        toggle={() => setIsPopupOpen(!isPopupOpen)}
                                        title="Rule Details"
                                    >
                                        <div data-testid='displayRuleQuery'>Rule for {ruleDescSelected}.<br/>
                                            Listed below are <b>[{ruleSelectedCount}] </b>rules that are mapped
                                            to {ruleDescSelected} attributes.<br/>
                                            You may edit or delete a rule, create a new rule or apply all rules to the
                                            current data.
                                        </div>
                                        <CFAdminDataTable
                                            columns={ruleQueryColumns}
                                            data={ruleGroupQueries}
                                            textModal={''}
                                            showAdd={false}
                                            pageSize={1000}
                                        />

                                        <CFAdminConfirmDialog
                                            data-testid="delete-rule"
                                            isOpen={isDelete}
                                            onClose={()=>setIsDelete(false)}
                                            onConfirm={()=>invokeRuleDelete(ruleId)}
                                            title={`Are you sure you want to delete rule?`}
                                            text="Are you sure you want to delete rule?"
                                        />
                                    </CFAdminModal>

                                    <CFAdminModal
                                        isOpen={isStepperOpen}
                                        toggle={() => toggleModal()}
                                        title="Create New Rule">

                                        <CFAdminReconcileAttribute
                                            attributesOptions={attributesOptions}
                                            vinMappedSourceAttributes={vinMappedOriginalSrcAttributes}
                                            vinMappedDstAttributes={vinMappedAttributes}
                                            handleAttributeOptions={handleAttributeOptions}
                                            ruleSourceAttributeValueDesc={ruleSourceAttributeValueDesc}
                                            ruleDstAttributeValueId={ruleDstAttributeValueId}
                                            successMessage={confirmSuccessMessage}
                                            errorMessage={confirmErrorMessage}
                                            toggleModal={toggleModal}
                                            isEdit={false}
                                            ruleId={ruleId}
                                            toggleEditModal={toggleEditModal}/>
                                    </CFAdminModal>

                                    <CFAdminModal
                                        isOpen={isEdit}
                                        toggle={() => toggleEditModal()}
                                        title="">

                                        <CFAdminReconcileAttribute
                                            attributesOptions={attributesOptions}
                                            vinMappedSourceAttributes={vinMappedSourceAttributes}
                                            vinMappedDstAttributes={vinMappedDstAttributes}
                                            handleAttributeOptions={handleAttributeOptions}
                                            ruleSourceAttributeValueDesc={ruleSourceAttributeValueDesc}
                                            ruleDstAttributeValueId={ruleDstAttributeValueId}
                                            successMessage={confirmSuccessMessage}
                                            errorMessage={confirmErrorMessage}
                                            toggleModal={toggleModal}
                                            isEdit={true}
                                            ruleId={ruleId}
                                            toggleEditModal={toggleEditModal}
                                        />
                                    </CFAdminModal>

                                </div>
                            </div>
                        </div>
                        <CFAdminNotification ref={childCompRef}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CFAdminManangeMappingRule;