import React, { useState, useEffect } from "react";
import VinImportService from "../../services/VinImportService";
import SimpleDatePicker from "../../components/CFAdminDatePicker";
import './CFAdminUnknownVins.scss';
import CFAdminNotification from "../../components/CFAdminNotification";
import CFAdminDataTable from "../../components/CFAdminDataTable";

interface VinData {
    id: string;
    vinX: string;
}

function CFAdminUnknownVins() {
    const childCompRef = React.useRef<any>();
    const [data, setData] = useState<VinData[]>([]);
    const [startDate, setStartDate] = useState<Date>(new Date());

    useEffect(() => {
        const fetchUnknownVins = async () => {
            try {
                if (!startDate) return;

                const response = await VinImportService.getUnknownVinFragments(startDate);
                setData(response.data);
            } catch (error) {
                console.error("Error fetching unknown VINs:", error);
            }
        };
        fetchUnknownVins().then(() => {});
    }, [startDate]);


    const columns = [
        {
            Header: "VINs",
            accessor: 'vinX'
        }
    ];

    const downloadCSV = () => {
        const csvRows = [
            ["VIN"],
            ...data.map(row => [row.vinX])
        ];

        const csvContent = csvRows.map(e => e.join(",")).join("\n");
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "unknown_vins.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (

        <div className="cf-admin-unknown-vins">
            <h2 className="fomo-type--title">Unknown VINs</h2>
            <div className="unknowns-since-date">
                <h3 className="unknowns-since-label">Unknown VINs since:</h3>
                <SimpleDatePicker selectedDate={startDate.toISOString().split('T')[0]} setSelectedDate={(date: string) => setStartDate(new Date(date))} />
            </div>

            <CFAdminDataTable
                columns={columns}
                data={data}
                showAdd={false}
                showFilter={false}
            />
            {data.length > 0 && <button className="fvp-button button-right-align" onClick={downloadCSV}>Download CSV</button>}


            <CFAdminNotification ref={childCompRef}/>
        </div>
    );
}

export default CFAdminUnknownVins;