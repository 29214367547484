import React, {useEffect, useRef, useState} from 'react';
import CFAdminDataTable from "../../components/CFAdminDataTable";
import ViewIcon from "../../assets/images/view-icon.svg";
import './CFAdminAttributes.scss'
import CFAdminNotification from '../../components/CFAdminNotification';
import VehicleConfigService from "../../services/VehicleConfigService";

interface Attribute {
    attributeId: number;
    attributeName: string;
    attributeDescription: string;
    vinMapped: boolean;
}
const handleViewAttributeRowClick = (id: number) => {
    console.log("View Attribute Row Clicked: " + id);
};
const  ViewAttributeValuesButton = ({props, titleDesc}: any)=> (
    <button
        className="hover-cursor"
        title={titleDesc}
        onClick={() => handleViewAttributeRowClick(props.row.original.attributeId)}
        data-testid={"attribute-" + props.row.original.id}
        style={{background: 'none', border: 'none', padding: 0, cursor: 'hover'}}
    >
        <img
            src={ViewIcon}
            alt="Xcelvations Logo"
            height="15"
        />
    </button>
);

function CFAdminAttributes() {
    const childCompRef = useRef<any>();
    const [data, setData] = useState<Attribute[]>([]);
    const [isOpen, setIsOpen] = useState(false);


    const columns = [
        {
            Header: "Id",
            accessor: 'attributeId'
        },
        {
            Header: "Name",
            accessor: 'attributeName'
        },
        {
            Header: "Description",
            accessor: 'attributeDescription'
        },
        {
            Header: "VIN Mapped",
            accessor: 'vinMapped',
            Cell: ({ value }: { value: boolean }) => (value ? "True" : "False")
        },
        {
            Header: "Actions",
            Cell: (props: any) => {
                return (
                    <ViewAttributeValuesButton props={props} titleDesc="View Attribute Values" />
                );
            }
        }
    ];

    const retrieveAttributes = () => {
        VehicleConfigService.getAttributesAll()
            .then(response => {
                setData(response.data.attributes);
            })
            .catch(e => {
                console.log(e);
            });
    };
    useEffect(() => {
        retrieveAttributes();
    }, []);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    }

    return (

        <div className="attribute-component">
            <h2 className="fomo-type--title">Manage Attributes</h2>


            <CFAdminDataTable
                columns={columns}
                data={data}
                toggleModal={toggleModal}
                textModal={'Add Attribute'}
                showAdd={false}
            />

            <CFAdminNotification ref={childCompRef}/>
        </div>
    );
}

export default CFAdminAttributes;