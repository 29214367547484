

const SimpleDatePicker = ({ selectedDate, setSelectedDate }: { selectedDate: string,
    setSelectedDate: (date: string) => void }) => {

    const handleDateChange = (event: any) => {
        setSelectedDate(event.target.value);
    };

    return (
        <div>
            <input
                type="date"
                value={selectedDate}
                onChange={handleDateChange}
            />
        </div>
    );
};

export default SimpleDatePicker;