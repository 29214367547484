import CFAdminNotification from "../../components/CFAdminNotification";
import React, {useEffect, useRef, useState} from "react";
import './CFAdminVinImport.scss';
import Stepper from "react-stepper-horizontal";
import CFAdminVinImportStatus from "./CFAdminVinImportStatus";
import VinImportService from "../../services/VinImportService";
import CFAdminConfirmDialog from "../../components/CFAdminConfirmDialog";
import CFAdminVinImportDetails from "./CFAdminVinImportDetails";
import {FaSyncAlt} from 'react-icons/fa';
import CFAdminDataTable from "../../components/CFAdminDataTable";
import CFAdminReconcileAttribute from "./CFAdminReconcileAttribute";
import CFAdminModal from "../../components/CFAdminModal";
import VehicleConfigService from "../../services/VehicleConfigService";
import AddIcon from "../../assets/images/add-icon.svg";
import CFAdminVinPatternForm from "./CFAdminVinPatternForm";
import {CheckboxButton} from "../../components/CFAdminDataTable/ActionsButtonComponents";
import ActionButtons from "../../util/renderActionButtons";

const stepMapping : any = {
    0: [1],
    1: [0, 2],
    2: [1, 3],
    3: [2, 4],
    4: [3, 5],
    5: [4]
};

// Define the type for latestImportStatus
interface ImportStatus {
    importRunning: boolean;
    currentProgress: string;
    currentStatus: string;
    previousStatus: string;
}

interface ReleasePreprocessReport {
    insertCount: number;
    updateCount: number;
    deleteCount: number;
    newMappingRulesCount: number;
}

export interface MultiAttributeSelectOption {
    readonly value: string;
    readonly label: string;
}
export interface VinPattern {
    patternId?: number;
    vinPattern: string;
    year: string;
    make: string;
    model: string;
    series: string;
    bodyType: string;
    engineDisplacement: string;
    cylinders: string;
    fuel: string;
    driveTrain: string;
}

const VinPatternActionCell = (props: any, handleChange: Function, handleDelete: Function) => {
    return <ActionButtons props={props}  handleChange={handleChange} handleDelete={handleDelete}/>
}


const GenerateConfigButton = ({ handleGenerate, props } : any) => (
    <div>
        <img
            className="hover-cursor"
            title="Generate New Config"
            src={AddIcon}
            data-testid={"modal-delete" + props.row.original.make}
            onClick={() => handleGenerate(props)}
            alt="Xcelvations Logo"
            height="15"
        />
    </div>
);


function CFAdminVinImport({ initialActiveStep = 0, initialStep = 0}) {
    const childCompRef = useRef<any>();
    const [latestImportData, setLatestImportData] = useState([]);
    const [vinFragmentDetails, setVinFragmentDetails] = useState([]);
    const [vinMappedAttributes, setVinMappedAttributes] = useState<MultiAttributeSelectOption[]>([]);
    const [latestImportStatus, setLatestImportStatus] = useState<ImportStatus | null>(null);
    const [latestRulesApplyStatus, setLatestRulesApplyStatus] = useState<ImportStatus | null>(null);
    const [activeStep, setActiveStep] = useState(initialActiveStep);
    const [nextStep, setNextStep] = useState(initialStep);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [isConfirmImportOpen, setIsConfirmImportOpen] = useState(false);
    const [isConfirmRulesOpen, setIsConfirmRulesOpen] = useState(false);
    const [isInternalVinOpen, setIsInternalVinOpen] = useState(false);
    const [selectedVinPattern, setSelectedVinPattern] = useState<VinPattern | undefined>(undefined);
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const [vinPatternDelete, setVinPatternDelete] = useState(false);
    const [isImportTriggered, setIsImportTriggered] = useState(false);
    const [isRulesApplyTriggered, setIsRulesApplyTriggered] = useState(false);
    const [isReconcileOpen, setIsReconcileOpen] = useState(false);
    const [attributesOptions, setAttributesOptions] = useState<MultiAttributeSelectOption[]>([]);
    const [internalVinPatternData, setInternalVinPatternData] = useState<any>([]);
    const [releasePreprocessReport, setReleasePreprocessReport] = useState<ReleasePreprocessReport | null>(null);
    const [initialValues, setInitialValues] = useState({
        vinFrag: "",
        attrId: "",
        attrName: "",
        selectedAttrName:"",
        sourceFieldValuesStr: "",
        destFieldValuesStr: "",
    });

    const steps = [
        {title: 'Import Latest VIN Data', onClick: () => setActiveStep(0)},
        {title: 'Apply All Rules', onClick: () => setActiveStep(1)},
        {title: 'Reconcile Unmapped Data', onClick: () => setActiveStep(2)},
        {title: 'Maintain Internal VIN Patterns', onClick: () => setActiveStep(3)},
        {title: 'Publish VIN data', onClick: () => setActiveStep(4)},
    ];

    const GenerateConfigButton = ({ handleGenerate, props, titleDesc } : any) => (
        <div>
            <img
                className="hover-cursor"
                title={titleDesc}
                src={AddIcon}
                data-testid={"rule-query"}
                alt="Xcelvations Logo"
                height="15"
            />
        </div>
    );

    const internalVinPatternColumns = [
        {
            Header: 'Selected',
            accessor: 'selected',
            Cell: (props: any) => {
                return <div>{renderSelectButton(props, () => handleVinsSelected(props, props.row.original))}</div>;
            }
        },
        {
            Header: "Pattern ID",
            accessor: 'patternId',
        },
        {
            Header: "VIN Pattern",
            accessor: 'vinPattern',
        },
        {
            Header: "Year",
            accessor: 'year',
        },
        {
            Header: "Make",
            accessor: 'make',
        },
        {
            Header: "Model",
            accessor: 'model',
        },
        {
            Header: "Deleted Flag",
            accessor: 'deletedFlag',
            Cell: ({ value }: { value: boolean }) => (value ? "True" : "False"),
            sortType: (rowA: any, rowB: any) => {
                const a = rowA.original.deletedFlag ? 1 : 0;
                const b = rowB.original.deletedFlag ? 1 : 0;
                return a - b;
            }
        },
        {
            Header: "Actions",
            Cell: (props:any) => VinPatternActionCell(props, handleChange, handleDelete )
        }
    ];

    const handleChange = (props: any) => {
        console.log("Change");
        toggleVinPatternModal(props.originalRow);
        setVinPatternDelete(false);
    }

    const handleDelete = (props: any) => {
        console.log("Delete");
        setVinPatternDelete(true);
        toggleVinPatternModal(props.originalRow);
    }

    const handleGenerate = (props: any) => {
        console.log("Generate Config for: ", props.row.original)
    }

    const handleColumnClick = async (vinFrag: any, column: any) => {

        setInitialValues((prevValues) => {
            const updatedInitialValues = {
                ...prevValues,
                vinFrag: vinFrag,
                attrId: column.attributeId,
                attrName: column.attributeName,
                selectedAttrName: "",
                sourceFieldValuesStr: "",
                destFieldValuesStr: "",
            };

            return updatedInitialValues;
        });
        await retrieveDestinationDetails();
        await getAttributes(vinFrag, column.attributeName);
        setIsReconcileOpen(true);
    };

    const toggleModal = () => {
        setIsReconcileOpen(!isReconcileOpen);
    }

    const toggleVinPatternModal = (vinPattern: VinPattern | null = null) => {
        setIsInternalVinOpen(!isInternalVinOpen);
        if (vinPattern) {
            setSelectedVinPattern(vinPattern);
        }
    }

    const handleVinsSelected = (event: any, vinPattern: VinPattern) => {
        setSelectedIds(prevSelectedIds => {
            if (vinPattern.patternId !== undefined) {
                if (prevSelectedIds.includes(vinPattern.patternId)) {
                    return prevSelectedIds.filter(id => id !== vinPattern.patternId);
                } else {
                    return [...prevSelectedIds, vinPattern.patternId];
                }
            }
            return prevSelectedIds;
        });
    };


    const renderSelectButton = (props: any, handleChange: Function) => {
        const {patternId} = props.row.original;
        const selected = selectedIds.includes(patternId);
        return <CheckboxButton {...props} checked={selected} handleChange={handleChange}/>;
    };

    const columns = [
        {
            Header: "VIN Fragment",
            accessor: 'vinFrag'
        },
        {
            Header: "Unmapped Attributes",
            accessor: 'nullColumns',
            Cell: (props: any) => {
                return (
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        {props.cell.row.original.nullAttributes.map((column: any, index: number) => (
                            <div key={`column-button-${column.attributeName}`} style={{marginRight: '10px'}}>
                                <button
                                    style={{
                                        background: 'none',
                                        border: 'none',
                                        color: 'blue',
                                        textDecoration: 'underline',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => handleColumnClick(props.cell.row.original.vinFrag, column)}
                                    data-testid={`column-button-${column.attributeName}`}
                                >
                                    {column.attributeName}
                                </button>
                            </div>
                        ))}
                    </div>
                )
            }
        }
    ];

    const getAttributes = async (vinFrag: any, attrName: any) => {
        const request = {
            "vinFragment": vinFrag
        };
        VinImportService.getVinFragmentAttributes(request)
            .then(response => {
                const attributes = response.data;
                let selectedAttr = "";
                if (attributes) {
                    let attributesSelectOptions : MultiAttributeSelectOption[] = [];
                    Object.keys(attributes).forEach((key) => {
                        if(key !== "vinFrag") {
                            const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
                            const data: MultiAttributeSelectOption = {
                                label: `v${capitalizedKey}='${attributes[key]}'`,
                                value: `v${capitalizedKey}='${attributes[key]}'`
                            };
                            attributesSelectOptions.push(data);

                            if (attrName.slice(1).toLowerCase() === key.toLowerCase()) {
                                selectedAttr = `v${capitalizedKey}='${attributes[key]}'`;

                                setInitialValues((prevValues) => {
                                    const updatedInitialValues = {
                                        ...prevValues,
                                        selectedAttrName:selectedAttr,
                                    };
                                    return updatedInitialValues;
                                });
                            }
                        }
                    });

                    setAttributesOptions(attributesSelectOptions);
                }
            })
            .catch(e => {
                console.log(e);
            });
    };

    const retrieveVinImportDetails = async () => {
        VinImportService.getLastImport()
            .then(response => {
                setLatestImportData(response.data);
            })
            .catch(e => {
                confirmErrorMessage("Failed to get Latest VIN Import Data")
                console.log(e);
            });
    };

    const retrieveDestinationDetails = async () => {
        VehicleConfigService.getVinMappedAttributes()
            .then(response => {
                let responseModel = response.data.attributes;
                if (responseModel) {
                    let attributes: MultiAttributeSelectOption[] = [];
                    responseModel.forEach((element: any) => {
                        let option: MultiAttributeSelectOption = {
                            value: element.attributeId!,
                            label: element.attributeName!
                        };
                        attributes.push(option);
                    });
                    setVinMappedAttributes(attributes);
                }
            })
            .catch(e => {
                confirmErrorMessage("Failed to get retrieveDestinationDetails")
                console.log(e);
            });
    };

    const retrieveVinFragmentDetails = async () => {
        VinImportService.getVinFragments()
            .then(response => {
                setVinFragmentDetails(response.data.unreconciledVinFragments);
            })
            .catch(e => {
                confirmErrorMessage("Failed to get Latest VIN Import Data")
                console.log(e);
            });
    };

    const retrieveInternalVinDetails = async () => {
        VinImportService.getInternalVins()
            .then(response => {
                if(response.data) {
                    setInternalVinPatternData(response.data);
                }
            })
            .catch(e => {
                confirmErrorMessage("Failed to get Latest VIN Import Data")
                console.log(e);
            });
    };

    const performNextOperation = () => {
        setIsConfirmOpen(false);
    }
    const retrieveVinImportStatus = async () => {
        VinImportService.getImportStatus()
            .then(response => {
                setLatestImportStatus(response.data);
                if(response.data.importRunning){
                    setIsImportTriggered(true);
                }else{
                    setIsImportTriggered(false);
                }
            })
            .catch(e => {
                confirmErrorMessage("Failed to get VIN Import Status")
                console.log(e);
            });
    };

    const retrieveRulesApplyStatus = async () => {
        VinImportService.getRulesApplyStatus()
            .then(response => {
                setLatestRulesApplyStatus(response.data);
                if(response.data.ruleRunning){
                    setIsRulesApplyTriggered(true);
                }else{
                    setIsRulesApplyTriggered(false);
                }
            })
            .catch(e => {
                confirmErrorMessage("Failed to get Rules Apply Status")
                console.log(e);
            });
    };

    const retrieveReleasePreprocessReport = async () => {
        VinImportService.getReleasePreprocessReport()
            .then(response => {
                if(isReleasePreprocessReport(response.data)){
                    setReleasePreprocessReport(response.data);
                }
            })
            .catch(e => {
                confirmErrorMessage("Failed to get Release Preprocess Report")
                console.log(e);
            });
    };

    const isReleasePreprocessReport = (data: any): data is ReleasePreprocessReport => (
        typeof data.insertCount === 'number' &&
        typeof data.updateCount === 'number' &&
        typeof data.deleteCount === 'number' &&
        typeof data.newMappingRulesCount === 'number'
    );

    const triggerVinImportConfirm = async () => {
        setIsConfirmImportOpen(true);
    }

    const triggerRulesApplyConfirm = async () => {
        setIsConfirmRulesOpen(true);
    }

    const triggerChangeFlag = async () => {

        let changeFlagPatterns: any[]  = [];

        selectedIds.forEach((id: number) => {
            changeFlagPatterns.push(id);
            return changeFlagPatterns;
        });
        setSelectedIds([]);

        const request = {
            "patternIds": changeFlagPatterns
        };

        if(changeFlagPatterns.length === 0){
            confirmErrorMessage("Please select rows to change flag");
        }

        VinImportService.updateFlag(request)
            .then(response => {
                confirmSuccessMessage("Flag Updated Successfully");
                retrieveInternalVinDetails();
            })
            .catch(e => {
                console.log(e);
            });
    }

    const releaseVinData = async () => {

        VinImportService.releaseVin({})
            .then(response => {
                confirmSuccessMessage("Submitted Release VIN Successfully");
            })
            .catch(e => {
                console.log(e);
            });
    }

    const triggerVinImport = async () => {
        VinImportService.initiateVinImport({})
            .then(response => {
                confirmSuccessMessage("VIN Import Triggered Successfully")
                setIsImportTriggered(true)
                setIsConfirmImportOpen(false)
                setTimeout(async () => {
                    await retrieveVinImportStatus();
                }, 1000); // 1 second in milliseconds
            })
            .catch(e => {
                confirmErrorMessage("Failed to get VIN Import Status")
                console.log(e);
            });
    };

    const triggerRulesApply = async () => {
        VinImportService.initiateRulesApply({})
            .then(response => {
                confirmSuccessMessage("Rules Apply Triggered Successfully")
                setIsRulesApplyTriggered(true)
                setIsConfirmRulesOpen(false)
                setTimeout(async () => {
                    await retrieveRulesApplyStatus();
                }, 1000); // 1 second in milliseconds
            })
            .catch(e => {
                confirmErrorMessage("Failed to get Rules Apply Status")
                console.log(e);
            });
    };

    useEffect(() => {
        getSectionComponent();
        if(activeStep === 0){
            retrieveVinImportDetails();
            retrieveVinImportStatus();
            retrieveRulesApplyStatus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, nextStep]);

    const handleStepperNext = (nextStep: number) => {
        setActiveStep(nextStep);
    };
    const handleNextClick = () => {
        if(activeStep === 0 && latestImportStatus?.importRunning){
            confirmErrorMessage("VIN Import is already running, please wait for the current import to complete.")
            return;
        }else if(activeStep === 1 && latestRulesApplyStatus?.importRunning){
            confirmErrorMessage("Rules Apply is already running, please wait for the current import to complete.")
            return;
        }else if(activeStep === 1){
            retrieveVinFragmentDetails();
        }else if(activeStep === 2){
            retrieveInternalVinDetails();
        }else if(activeStep === 3) {
            retrieveReleasePreprocessReport();
        }

        setNextStep(activeStep + 1);
    };

    const handlePreviousClick = () => {
        setNextStep(activeStep - 1);
    };

    function getSectionComponent() {
        const nextSteps = stepMapping[activeStep];

        if (nextSteps.includes(nextStep)) {
            handleStepperNext(nextStep);
        } else {
            console.error(stepMapping+" :: Unrecognized step :: "+activeStep);
        }
    }

    const confirmSuccessMessage = async (value: any) => {
        childCompRef.current.successMessage(value);
    };

    const confirmErrorMessage = async (value: any) => {
        childCompRef.current.failureMessage(value);
    };

    const handleAttributeOptions = (values : any) => {
        console.log("Selected Values: ", values);
    }

    return (
        <>
            <div className="vinimport-component">
                <h2 className="fomo-type--title">VIN Admin - Vin Import</h2>
                <div>
                    <div>
                        <div>
                            <div>
                                <div className="active-step">
                                    {activeStep === 0 && (
                                        <CFAdminVinImportDetails latestImportData={latestImportData}/>
                                    )}
                                    <div className="stepper">
                                        <Stepper steps={steps} activeStep={activeStep}/>
                                        <div className="active-step">
                                            <>
                                                {activeStep === 0 && (
                                                    <>
                                                        <div className="ext3">
                                                            VIN Import is an automated job that runs every Thursday.
                                                            If you want to manually upload the latest details from
                                                            Polk, please click on 'Import VIN Data.'
                                                            <button data-testid="step-refresh"
                                                                    className="fvp-button button-right-align"
                                                                    type="button"
                                                                    onClick={retrieveVinImportStatus}>
                                                                <FaSyncAlt/> Refresh Status
                                                            </button>
                                                        </div>

                                                        {latestImportStatus &&
                                                            <>
                                                                <CFAdminVinImportStatus
                                                                    tableHeader="VIN Import State"
                                                                    columnHeader="Import Running"
                                                                    latestImportStatus={latestImportStatus} runningFlag="importRunning"/>
                                                            </>
                                                        }
                                                    </>
                                                )}
                                                {activeStep === 1 && (
                                                    <>
                                                        <div className="ext3">
                                                            It is recommended to apply all rules before proceeding.
                                                            <button data-testid="step-refresh"
                                                                    className="fvp-button button-right-align"
                                                                    type="button"
                                                                    onClick={retrieveRulesApplyStatus}>
                                                                <FaSyncAlt/> Refresh Status
                                                            </button>

                                                            {latestRulesApplyStatus &&
                                                                <>
                                                                    <>
                                                                        <CFAdminVinImportStatus
                                                                            tableHeader="Apply Rules State"
                                                                            columnHeader="Apply Rules Running"
                                                                            latestImportStatus={latestRulesApplyStatus}
                                                                            runningFlag="ruleRunning"
                                                                        />
                                                                    </>
                                                                </>
                                                            }
                                                        </div>
                                                    </>
                                                )}
                                                {activeStep === 2 && (
                                                    <>
                                                        <div className="ext2">
                                                            There are <b>{vinFragmentDetails.length}</b> VIN records from the last import that have
                                                            unmapped attributes.
                                                            Map data to an attribute by clicking on its attribute
                                                            link below.
                                                            <CFAdminDataTable
                                                                columns={columns}
                                                                data={vinFragmentDetails}
                                                                showAdd={false}
                                                            />

                                                            <CFAdminModal
                                                                isOpen={isReconcileOpen}
                                                                toggle={toggleModal}
                                                                isAdd={true}
                                                                title={"Reconcile Unmapped Attributes"}
                                                            >
                                                                <CFAdminReconcileAttribute
                                                                    {...initialValues}
                                                                    attributesOptions={attributesOptions}
                                                                    vinMappedAttributes={vinMappedAttributes}
                                                                    handleAttributeOptions={handleAttributeOptions}
                                                                    successMessage={confirmSuccessMessage}
                                                                    errorMessage={confirmErrorMessage}
                                                                    toggleModal={toggleModal}/>
                                                            </CFAdminModal>

                                                        </div>
                                                    </>
                                                )}
                                                {activeStep === 3 && (
                                                    <>
                                                    <CFAdminModal
                                                        isOpen={isInternalVinOpen}
                                                        toggle={toggleVinPatternModal}
                                                        isAdd={true}
                                                        title={"VIN Pattern Data"}>
                                                        <CFAdminVinPatternForm
                                                            vinPattern={selectedVinPattern}
                                                            toggleModal={toggleVinPatternModal}
                                                            refreshData={retrieveInternalVinDetails}
                                                            successMessage={confirmSuccessMessage}
                                                            errorMessage={confirmErrorMessage}
                                                            isDelete={vinPatternDelete}
                                                            />
                                                    </CFAdminModal>
                                                        <CFAdminDataTable
                                                            columns={internalVinPatternColumns}
                                                            data={internalVinPatternData}
                                                            textModal={'Add Vin Pattern'}
                                                            toggleModal={toggleVinPatternModal}
                                                            showAdd={true}
                                                            pageSize={50}
                                                        />
                                                    </>
                                                )}
                                                {activeStep === 4 && (
                                                    <>
                                                        <div className="ext4">
                                                            <b>Release Pre-process Report:</b>
                                                            <div className="ext5">
                                                                <div>Insert Count: {releasePreprocessReport?.insertCount}</div>
                                                                <div>Update Count: {releasePreprocessReport?.updateCount}</div>
                                                                <div>Delete Count: {releasePreprocessReport?.deleteCount}</div>
                                                                <div>New Mapping Rules Count: {releasePreprocessReport?.newMappingRulesCount}</div>
                                                            </div>
                                                        </div>
                                                        <div className="ext1">
                                                            Please click on 'Release VIN' to publish the latest VIN data.
                                                            It takes up to 3 minutes to update the tables.
                                                        </div>
                                                        <div className="ext6">
                                                            <br/>
                                                            You will receive a notification email from WSMOD_NOTIFICATION once it is complete.
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                        </div>
                                        <div className="modal-footer button-frame">
                                            <div className="right-align">
                                                {(activeStep !== 0) && (
                                                    <button data-testid="step-prev"
                                                            className="fvp-button button-right-align" type="button"
                                                            onClick={handlePreviousClick}>
                                                        <i className="fvp-left-chevron"/> Previous
                                                    </button>
                                                )}
                                                {(activeStep === 0) && (
                                                    <>
                                                        <button data-testid="step-import-vin"
                                                                className="fvp-button button-right-align" type="button"
                                                                onClick={triggerVinImportConfirm}
                                                                disabled={isImportTriggered}>
                                                            <i className="fvp-left-chevron"/> Import VIN Data
                                                        </button>
                                                    </>
                                                )}
                                                {(activeStep === 1) && (
                                                    <>
                                                        <button data-testid="step-prev"
                                                                className="fvp-button button-right-align"
                                                                type="button"
                                                                onClick={triggerRulesApplyConfirm}
                                                                disabled={isRulesApplyTriggered}>
                                                            Apply Rules
                                                        </button>
                                                    </>
                                                )}
                                                {(activeStep === 3) && (
                                                    <>
                                                        <button data-testid="step-change-flag"
                                                                className="fvp-button button-right-align"
                                                                type="button"
                                                                onClick={triggerChangeFlag}>
                                                            Change Flag
                                                        </button>
                                                    </>
                                                )}
                                                {(activeStep === 4) && (
                                                    <>
                                                        <button data-testid="step-release-vin"
                                                                className="fvp-button button-right-align"
                                                                type="button"
                                                                onClick={releaseVinData}>
                                                            Release VIN
                                                        </button>
                                                    </>
                                                )}
                                                {activeStep !== steps.length - 1 && (
                                                    <button data-testid="step-next"
                                                            className="fvp-button button-right-align" type="button"
                                                            onClick={() => handleNextClick()}>
                                                        Next <i className="fvp-right-chevron"/>
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <CFAdminNotification ref={childCompRef}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CFAdminConfirmDialog
                isOpen={isConfirmOpen}
                onClose={()=>setIsConfirmOpen(false)}
                onConfirm={performNextOperation}
                title={"VIN Import Report "}
                text="The VIN data import was successful!"
            />
            <CFAdminConfirmDialog
                isOpen={isConfirmImportOpen}
                onClose={()=>setIsConfirmImportOpen(false)}
                onConfirm={triggerVinImport}
                title={"VIN Import Action"}
                text="Are you sure You want to trigger VIN Import?"
            />
            <CFAdminConfirmDialog
                isOpen={isConfirmRulesOpen}
                onClose={()=>setIsConfirmRulesOpen(false)}
                onConfirm={triggerRulesApply}
                title={"Rules Apply Action"}
                text="Are you sure You want to apply the Rules?"
            />
        </>
    );
}

export default CFAdminVinImport;
