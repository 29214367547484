import React, {useEffect, useState} from "react";
import Stepper from "react-stepper-horizontal";

import DualListBox from "react-dual-listbox";
import VinImportService from "../../services/VinImportService";
import CFAdminVinDestinationValues from "./CFAdminVinDestinationValues";
import {MultiAttributeSelectOption} from "./index";

type CFAdminReconcileProps = {
    handleAttributeOptions: any;
    vinMappedSourceAttributes: any;
    vinMappedDstAttributes: any;
    attributesOptions:any;
    ruleSourceAttributeValueDesc:any;
    ruleDstAttributeValueId:any;
    toggleModal: any;
    successMessage: any;
    errorMessage: any;
    isEdit: boolean;
    ruleId: any;
    toggleEditModal: any;
};

const stepMapping : any = {
    0: [1],
    1: [0, 2],
    2: [1, 3],
    3: [2,4],
    4: [3]
};

function CFAdminReconcileAttribute ({
                                        handleAttributeOptions,
                                        vinMappedSourceAttributes,
                                        vinMappedDstAttributes,
                                        toggleModal,
                                        successMessage,
                                        errorMessage,
                                        isEdit,
                                        ruleDstAttributeValueId,
                                        ruleSourceAttributeValueDesc,
                                        ruleId,toggleEditModal
                                    }: Readonly<CFAdminReconcileProps>) {

    const [activeStep, setActiveStep] = useState(0);
    const steps = [
        {title: 'Choose Source Attributes', onClick: () => setActiveStep(0)},
        {title: 'Choose Source Field Values', onClick: () => setActiveStep(1)},
        {title: 'Choose Destination Attributes', onClick: () => setActiveStep(2)},
        {title: 'Choose Destination Field Values', onClick: () => setActiveStep(3)},
        {title: 'Save and Apply Rule', onClick: () => setActiveStep(4)},
    ];
    const [nextStep, setNextStep] = useState(0);

    const [selectedSourceOptions, setSelectedSourceOptions] = useState<any[]>([]);
    const [selectedDestOptions, setSelectedDestOptions] = useState<any[]>([]);
    const [attributeOptionsSourceMap, setAttributeOptionsSourceMap] = useState<Map<string, MultiAttributeSelectOption[]>>(new Map());
    const [attributeOptionsDescMap, setAttributeOptionsDescMap] = useState<Map<string, MultiAttributeSelectOption[]>>(new Map());
    const [isForBrandRule, setIsForBrandRule] = useState(false);
    const [isSeriesSelected, setIsSeriesSelected] = useState(true);
    const [newDescValue, setNewDescValue] = useState("");
    const [selectValue, setSelectValue] = useState("");
    const [selectLabel, setSelectLabel] = useState("");
    const [isSeriesDstSelected, setIsSeriesDstSelected] = useState(true);
    const [newDescDstValue, setNewDescDstValue] = useState("");
    const [selectDstValue, setSelectDstValue] = useState("");
    const [selectDstLabel, setSelectDstLabel] = useState("");
    const [sourceAttributeKey, setSourceAttributeKey] = useState("");

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsForBrandRule(event.target.checked);
    };

    const handleSourceSelected = async (selectedOptions: any) => {
        handleAttributeOptions(selectedOptions);

        if(selectedSourceOptions.length > 1 || selectedOptions.length > 1) {
            errorMessage("Please select only one source attribute");
        }
        else {
            setSelectedSourceOptions(selectedOptions);
        }
    };

    const handleDestSelected = async (selectedOptions: any) => {
        handleAttributeOptions(selectedOptions);
        if(selectedDestOptions.length > 1 || selectedOptions.length > 1)
            errorMessage("Please select only one Destination attribute");
        else
            setSelectedDestOptions(selectedOptions);
    };

    useEffect(() => {
        const nextSteps = stepMapping[activeStep];
        if (nextSteps.includes(nextStep)) {
            handleStepperNext(nextStep);
        } else {
            console.error("Unrecognized step");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, nextStep]);

    const handleNextClick = () => {
        if(activeStep === 0){
            getVehicleSourceAttributeValues(selectedSourceOptions[0]);

            if(selectedSourceOptions.length < 1){
                errorMessage("Please select source attributes");
                return;
            }
        }
        if(activeStep === 2){
            getVehicleDescAttributeValues(selectedDestOptions[0]);

            if(selectedDestOptions.length < 1){
                errorMessage("Please select attributes");
                return;
            }
        }
        setNextStep(activeStep + 1);
    };

    const handleAttributeSelected = async (id: any, value: any, text: any) => {
        if(activeStep === 0 || activeStep === 1) {
            if (id === "NEW") {
                setNewDescValue(value);
                setIsSeriesSelected(false);
            } else if (id === "SELECT") {
                setSelectValue(value);
                setSelectLabel(text);
                setIsSeriesSelected(true);
            } else if (id === "RADIO") {
                setIsSeriesSelected(value);
            }
        } else {
            if (id === "NEW") {
                setNewDescDstValue(value);
                setIsSeriesDstSelected(false);
            } else if (id === "SELECT") {
                setSelectDstValue(value);
                setSelectDstLabel(text);
                setIsSeriesDstSelected(true);
            } else if (id === "RADIO") {
                setIsSeriesDstSelected(value);
            }
        }
    };

    const getVehicleDescAttributeValues = async (id : any) => {
        const request = {
            "attributeIds": [id]
        };
        VinImportService.getVehicleAttributeValues(request)
            .then(response => {

                let attributeValuesMap = new Map<string, MultiAttributeSelectOption[]>();
                if(response.data.attributeValues.length  > 0) {
                    // Sort the attributeValues array by attributeValueDescription in ascending order
                    response.data.attributeValues.sort((a: any, b: any) => {
                        return a.attributeValueDescription.localeCompare(b.attributeValueDescription);
                    });
                    response.data.attributeValues.forEach((element: any) => {
                        const data: MultiAttributeSelectOption = {
                            label: element.attributeValueDescription,
                            value: element.attributeValueId
                        };
                        if (!attributeValuesMap.has(element.attributeId+"-"+element.attributeDescription)) {
                            attributeValuesMap.set(element.attributeId+"-"+element.attributeDescription, []);
                        }
                        attributeValuesMap.get(element.attributeId+"-"+ element.attributeDescription)?.push(data);
                        if(element.attributeValueId===ruleDstAttributeValueId)
                        {
                            setSelectDstValue(element.attributeValueId);
                            handleAttributeSelected("SELECT", element.attributeValueId, element.attributeValueDescription);
                        }

                    });
                }
                setAttributeOptionsDescMap(attributeValuesMap);
            })
            .catch(e => {
                errorMessage("Failed to get getVehicleAttributeValues Data")
                console.log(e);
            });
    };

    const getVehicleSourceAttributeValues = async (id: any) => {

        // Find the selected option in the vinMappedSourceAttributes array
        const selectedOption = vinMappedSourceAttributes.find((option: any) => option.value === id);

        if (selectedOption) {
            const selectedLabel = selectedOption.label.substring(1);
            setSourceAttributeKey(id);

            VinImportService.getVehicleSourceAttributeValues(selectedLabel)
                .then(response => {
                    let attributeValuesMap = new Map<string, MultiAttributeSelectOption[]>();
                    if (response.data.sourceValues.length > 0) {
                        // Sort the sourceValues array by sourceAttributeValue in ascending order
                        response.data.sourceValues.sort((a: any, b: any) => {
                            return a.sourceAttributeValue.localeCompare(b.sourceAttributeValue);
                        });
                        response.data.sourceValues.forEach((element: any) => {
                            const data: MultiAttributeSelectOption = {
                                label: element.sourceAttributeValue,
                                value: element.sourceAttributeValue
                            };
                            if (!attributeValuesMap.has(response.data.sourceColumn)) {
                                attributeValuesMap.set(response.data.sourceColumn, []);
                            }
                            attributeValuesMap.get(response.data.sourceColumn)?.push(data);

                            if (element.sourceAttributeValue === ruleSourceAttributeValueDesc) {
                                setSelectValue(element.sourceAttributeValue);
                                handleAttributeSelected("SELECT", element.sourceAttributeValue, element.sourceAttributeValue);
                            }
                        });
                    }
                    setAttributeOptionsSourceMap(attributeValuesMap);
                })
                .catch(e => {
                    errorMessage("Failed to get getVehicleSourceAttributeValues Data");
                    console.log(e);
                });
        }
    };

    const handlePreviousClick = () => {
        setNextStep(activeStep - 1);
    };

    const handleSaveClick = () => {
        const sourceAttribute = Array.from(attributeOptionsSourceMap.keys()).map(key => {
            const parts = key.split('-');
            return parts.length > 1 ? parts[1] : key;
        });

        let sourceAttributeValues: any[] = [
            {
                sourceColumn: sourceAttribute[0],
                sourceValue: isSeriesSelected ? selectLabel : newDescValue,
            }
        ];
        let destinationAttributeValues: any[] = [
            {
                "attributeId": selectedDestOptions[0],
                "attributeValueId": isSeriesDstSelected ? +selectDstValue : null,
                "attributeValueDescription": !isSeriesDstSelected ? newDescDstValue : " "
            }
        ];
        const request = {
            "sourceAttributeValues": sourceAttributeValues,
            "destinationAttributeValues": destinationAttributeValues,
            "fordFlag": isForBrandRule
        };

        VinImportService.saveRule(request)
            .then(response => {
                if (response.data) {
                    successMessage("Rule saved successfully-", response.data.description);
                    toggleModal();
                }
            })
            .catch(e => {
                errorMessage("Failed to get handleSaveClick");
                console.log(e);
            });

        toggleModal();
    };


    const handleEditClick = () => {

        const request = {
            fieldMapKey: sourceAttributeKey,
            vendorValue: isSeriesSelected ? selectLabel : newDescValue,
            "destinationAttributeId": selectedDestOptions[0],
            "destinationAttributeValueId": isSeriesDstSelected ? +selectDstValue : null,
            "destinationAttributeValueDesc": !isSeriesDstSelected ? newDescDstValue : " "

        };

        VinImportService.editRule(request,ruleId)
            .then(response => {
                if (response.data) {
                    successMessage("Rule Edited successfully-", response.data.description);
                    toggleModal();
                }
            })
            .catch(e => {
                errorMessage("Failed to get handleEditClick");
                console.log(e);
            });

        toggleEditModal();
    };

    const handleStepperNext = (nextStep: number) => {
        setActiveStep(nextStep);
    };

    return (
        <div className="stepper" data-testid="create-rule-stepper">
            <Stepper steps={steps} activeStep={activeStep}/>
            <div className="active-step">
                <>
                    {activeStep === 0 && (
                        <>
                            <div className="ext2">
                                Please select the source attribute attribute field for the rule.
                            </div>
                            <div className="ext4 scroll" data-testid='attributes-test'>
                                <DualListBox
                                    options={vinMappedSourceAttributes ? vinMappedSourceAttributes : []}
                                    selected={selectedSourceOptions}
                                    onChange={(selected: any) => {
                                        handleSourceSelected(selected);
                                    }}
                                />
                            </div>
                        </>
                    )}
                    {activeStep === 1 && (
                        <>
                            <div className="ext2">
                                Please select the source attribute attribute value for the rule.
                            </div>
                            <div className="ext4 scroll" data-testid='stepper2'>
                                {attributeOptionsSourceMap && (
                                    <CFAdminVinDestinationValues
                                        handleAttributeSelected={handleAttributeSelected}
                                        attributeOptionsMap={attributeOptionsSourceMap}
                                        selectedValue={selectValue}
                                        newDescValue={newDescValue}
                                        isRadioChecked={isSeriesSelected}
                                        textDesc={"Source Attribute"}
                                        attributeDesc={Array.from(attributeOptionsSourceMap.keys()).map(key => {
                                            const parts = key.split('-');
                                            return parts.length > 1 ? parts[1] : key;
                                        })}
                                    />
                                )}
                            </div>
                        </>
                    )}
                    {activeStep === 2 && (
                        <>
                            <div className="ext2">
                                Please select the Destination attribute field for the rule.
                            </div>

                            <div className="ext4 scroll" data-testid='stepper3'>
                                <DualListBox
                                    options={vinMappedDstAttributes ? vinMappedDstAttributes : []}
                                    selected={selectedDestOptions}
                                    onChange={(selected: any) => {
                                        handleDestSelected(selected);
                                    }}
                                    data-testid='attributes-test'
                                />
                            </div>
                        </>
                    )}
                    {activeStep === 3 && (
                        <>
                            <div className="ext2">
                                Please select the Destination attribute attribute field value for the rule.
                            </div>

                            <div className="ext4 scroll" data-testid='stepper4'>
                                {attributeOptionsDescMap && (
                                    <CFAdminVinDestinationValues
                                        handleAttributeSelected={handleAttributeSelected}
                                        attributeOptionsMap={attributeOptionsDescMap}
                                        selectedValue={selectDstValue}
                                        newDescValue={newDescDstValue}
                                        isRadioChecked={isSeriesDstSelected}
                                        textDesc={"Destination Attribute"}
                                        attributeDesc={Array.from(attributeOptionsDescMap.keys()).map(key => key.split('-')[1])}/>
                                )}
                            </div>
                        </>
                    )}
                    {activeStep === 4 && (
                        <>
                            <div className="ext2">
                                Displayed below is your new mapping rule.
                            </div>
                            <div className="ext2">
                                Click Save and Apply to save the new rule and reapply the rules to the data.
                            </div>
                            <div className="ext2" data-testid='stepper5'>
                                New Mapping Rule: ----
                                <b>
                                    If {Array.from(attributeOptionsSourceMap.keys()).map(key => {
                                    const parts = key.split('-');
                                    return parts.length > 1 ? parts[1] : key;
                                })} ='{isSeriesSelected ? selectLabel : newDescValue}'
                                    Then {Array.from(attributeOptionsDescMap.keys()).map(key => key.split('-')[1])} =
                                    '{isSeriesDstSelected ? selectDstLabel : newDescDstValue}'
                                </b>
                            </div>
                            <div className="ext4">
                                <input
                                    type="checkbox"
                                    name="forBrandRule"
                                    checked={isForBrandRule}
                                    onChange={handleCheckboxChange}
                                /> Ford Brand Rule
                            </div>
                        </>
                    )}
                </>
                <div className="modal-footer button-frame">
                    <div className="right-align">
                        {(activeStep !== 0) && (
                            <button data-testid="step-popup-prev"
                                    className="fvp-button button-right-align" type="button"
                                    onClick={handlePreviousClick}>
                                <i className="fvp-left-chevron"/> Previous
                            </button>
                        )}
                        {activeStep !== steps.length - 1 && (
                            <button data-testid="step-popup-next"
                                    className="fvp-button button-right-align" type="button"
                                    onClick={() => handleNextClick()}>
                                Next <i className="fvp-right-chevron"/>
                            </button>
                        )}
                        {(activeStep === 4) && (
                            <div>
                                {!isEdit && (
                                    <button data-testid="step-popup-save"
                                            className="fvp-button button-right-align" type="button"
                                            onClick={handleSaveClick}>
                                        Save Rule
                                    </button>
                                )}
                                {isEdit && (
                                    <button data-testid="step-popup-edit"
                                            className="fvp-button button-right-align" type="button"
                                            onClick={handleEditClick}>
                                        Edit Rule
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CFAdminReconcileAttribute;