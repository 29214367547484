import React from "react";
import {MultiAttributeSelectOption} from "./index";
import * as Yup from 'yup';
import {FVPTextInput} from "../../components/formField";
import {Formik} from "formik";

interface CFAdminVinDestinationValuesProps {
    attributeOptionsMap: Map<string, MultiAttributeSelectOption[]>;
    handleAttributeSelected:any;
    selectedValue: any;
    newDescValue: any;
    isRadioChecked:boolean;
    textDesc: any;
    attributeDesc: any;
}

// Creating schema
const schema = Yup.object().shape({
    newDesc: Yup.string()
        .min(1, "Make description must be at least 3 characters")
        .max(100,"Make desc must be less than 10 characters"),
    destId: Yup.string()
});

function CFAdminVinDestinationValues({ attributeOptionsMap, handleAttributeSelected, selectedValue, newDescValue, isRadioChecked, textDesc, attributeDesc}: CFAdminVinDestinationValuesProps) {

    const handleNewDescChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleAttributeSelected("NEW", event.target.value, null);
    };

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        const label = event.target.options[event.target.selectedIndex].text;
        handleAttributeSelected("SELECT", value, label);
    };

    const handleRadioChange = (isSeries: boolean) => {
        handleAttributeSelected("RADIO", isSeries, null);
    };

    return (
        <>
            <Formik
                validationSchema={schema}
                initialValues={{id: ''}}
                onSubmit={(values) => {
                }}
            >{() => (
            <table>
                <thead>
                <tr>
                    <th>{textDesc}</th>
                    <th>Choose a Value OR Enter a {textDesc} Value</th>
                </tr>
                </thead>
                <tbody>
                {Array.from(attributeOptionsMap.entries()).map(([key, value]: [string, MultiAttributeSelectOption[]]) => (
                    <tr key={key}>
                        <td>{attributeDesc}</td>
                        <td>
                            <input
                                type="radio"
                                name="chksSeries"
                                value="0"
                                onChange={() => handleRadioChange(true)}
                                checked={isRadioChecked}
                            />
                            <select
                                className="form-control"
                                name="destId"
                                id="destId"
                                data-testid="destId"
                                disabled={!isRadioChecked}
                                onChange={handleSelectChange}
                                value={selectedValue}
                            >
                                <option value="">Select</option>
                                {value.map((option: MultiAttributeSelectOption) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            <br/>
                            <input
                                type="radio"
                                name="chksSeries"
                                value="1"
                                onChange={() => handleRadioChange(false)}
                                checked={!isRadioChecked}
                            />
                            <FVPTextInput
                                label="New Description: "
                                name="newDesc"
                                disabled={isRadioChecked}
                                onChange={handleNewDescChange}
                                value={newDescValue}
                            />
                            <br/>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            )}
            </Formik>
        </>
    );
}

export default CFAdminVinDestinationValues;