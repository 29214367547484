import { authHeader } from "../util/auth-header";
import http from "../util/http-common";

export class VehicleConfigService {
  static getVehicleMakes() {
    return http.get("/cfadminvehicleconfig/api/v1/mmy/makes",{ headers: authHeader()});
  }

  static getVehicleModels(make: string, year: string) {
    return http.get(`/cfadminvehicleconfig/api/v1/mmy/models?make=${make}&year=${year}`,{ headers: authHeader()});
  }

  static getVehicleYears() {
    return http.get("/cfadminvehicleconfig/api/v1/mmy/years",{ headers: authHeader()});
  }

  static getAttributes(modelYearId: string) {
    return http.get(`/cfadminvehicleconfig/api/v1/attributes?modelYearId=${modelYearId}`,{ headers: authHeader()});
  }

  static getAttributesAll() {
    return http.get(`/cfadminvehicleconfig/api/v1/attributes`,{ headers: authHeader()});
  }

  static getVehicleAttributes(data: any) {
    return http.post(`/cfadminvehicleconfig/api/v1/mmy`,data ,{ headers: authHeader()});
  }

  static getVehicleAttributeValues(data: any) {
    return http.post(`/cfadminvehicleconfig/api/v1/attribute-values-query`, data, { headers: authHeader()});
  }

  static getVehicleConfigurations(data: any) {
    return http.post(`/cfadminvehicleconfig/api/v1/generate-configuration-combination`, data, { headers: authHeader()});
  }

  static getVehicleConfigurationDetails(make: any, model: any, year: any) {
    return http.get(`/cfadminvehicleconfig/api/v1/vehicle?make=${make}&year=${year}&model=${model}`, { headers: authHeader()});
  }

  static getVehicleConfigurationsWithId(modelYearId: any) {
    return http.get(`/cfadminvehicleconfig/api/v1/vehicle/modelyear-query?modelYearId=${modelYearId}`, { headers: authHeader()});
  }

  static deleteModelYear(modelYearId: any) {
    return http.delete(`/cfadminvehicleconfig/api/v1/vehicle/modelyear/${modelYearId}`, { headers: authHeader()});
  }

  static deleteVehicle(vehicleId: any) {
    return http.delete(`/cfadminvehicleconfig/api/v1/vehicle/${vehicleId}`, { headers: authHeader()});
  }

  static deleteVehicleAttribute(attributeID: any, modelYearId: any) {
    return http.delete(`/cfadminvehicleconfig/api/v1/vehicle/attribute?attributeID=${attributeID}&modelYearID=${modelYearId}`, { headers: authHeader()});
  }

  static getVehicleAttributeCombinations(data: any) {
    return http.post(`/cfadminvehicleconfig/api/v1/vehicle/generate-configuration-combination`, data, { headers: authHeader()});
  }

  static createVehicleConfigurations(data: any) {
    return http.post(`/cfadminvehicleconfig/api/v1/vehicle/create-new-configuration`, data, { headers: authHeader()});
  }

  static getSystemSuggestedModels() {
    return http.get(`/cfadminvehicleconfig/api/v1/system-suggested-models`, { headers: authHeader()});
  }

  static getVinMappedAttributes() {
    return http.get(`/cfadminvehicleconfig/api/v1/attributes?vinMapped=true`, { headers: authHeader()});
  }

  static getSystemSuggestedConfigurations(data: any) {
    return http.post(`/cfadminvehicleconfig/api/v1/system-suggested-configurations-query`, data,{ headers: authHeader()});
  }

  static getSystemSuggestedVehicles() {
    return http.get(`/cfadminvehicleconfig/api/v1/suggested-vehicles`,{ headers: authHeader()});
  }

  static discardVehicles(data: any) {
    return http.put(`/cfadminvehicleconfig/api/v1/suggested-vehicles/discard-vehicles`, data, { headers: authHeader()});
  }

  static getUnSupportedVehicles() {
    return http.get(`/cfadminvehicleconfig/api/v1/unsupported-vehicles`,{ headers: authHeader()});
  }

  static supportVehicles(data: any) {
    return http.put(`/cfadminvehicleconfig/api/v1/unsupported-vehicles`, data, { headers: authHeader()});
  }

  static getScheduleMaintenanceDrivingConditions(request: any) {
    return http.post(`/cfadminvehicleconfig/api/v1/vehicle-driving-conditions-query`, request, { headers: authHeader()});
  }

  static getScheduleMaintenanceVehicles(request: any) {
    return http.post(`/cfadminvehicleconfig/api/v1/vehicle-configuration`, request,{ headers: authHeader()});
  }

  static getScheduleMaintenanceIntervals(vehicleDrivingConditionId: any) {
    return http.get(`/cfadminvehicleconfig/api/v1/maintenance-schedules/${vehicleDrivingConditionId}`, { headers: authHeader()});
  }

  static getVehicleScheduleMaintenance(vehicleDrivingConditionId: any, scheduleCode: any, countryLanguageId: any ) {
    return http.get(`/cfadminvehicleconfig/api/v1/maintenance-schedules/${vehicleDrivingConditionId}/${scheduleCode}?countryLanguageId=${countryLanguageId}`, { headers: authHeader()});
  }

  static getDecodeVin(vin: any ) {
    return http.get(`/cfadminvehicleconfig/api/v1/vehicle-configuration/decode-vin-data?vin=${vin}`, { headers: authHeader()});
  }

  static decodeTestVin(request: any ) {
    return http.post(`/cfadminvehicleconfig/api/v1/vehicle-configuration/decode-test-client-data`, request, { headers: authHeader()});
  }
}

export default VehicleConfigService;