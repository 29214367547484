import { authHeader } from "../util/auth-header";
import http from "../util/http-common";

export class VinImportService {

  static getLastImport() {
    return http.get("/cfadminvinadmin/api/v1/vin-import",{ headers: authHeader()});
  }

  static getImportStatus() {
    return http.get("/cfadminvinadmin/api/v1/vin-import/status",{ headers: authHeader()});
  }

  static initiateVinImport(data: any) {
    return http.post(`/cfadminvinadmin/api/v1/vin-import`,data ,{ headers: authHeader()});
  }

  static getRulesApplyStatus() {
    return http.get("/cfadminvinadmin/api/v1/vin-pattern-apply-rules/status",{ headers: authHeader()});
  }

  static initiateRulesApply(data: any) {
    return http.post(`/cfadminvinadmin/api/v1/vin-pattern-apply-rules`,data ,{ headers: authHeader()});
  }

  static getVinFragments() {
    return http.get("/cfadminvinadmin/api/v1/unreconciled-vin-fragments",{ headers: authHeader()});
  }

  static getVinFragmentAttributes(data: any) {
    return http.post("/cfadminvinadmin/api/v1/unreconciled-vin-fragments/attributes", data, { headers: authHeader()});
  }

  static getVehicleAttributeValues(data: any) {
    return http.post(`/cfadminvehicleconfig/api/v1/attribute-values-query/by-attribute-ids`, data, { headers: authHeader()});
  }

  static getVehicleSourceAttributeValues(attributedesc: any) {
    return http.get(`/cfadminvinadmin/api/v1/mapping-rules/source-attribute-values?attribute=${attributedesc}`, { headers: authHeader()});
  }

  static saveRule(data: any) {
    return http.post(`/cfadminvinadmin/api/v1/mapping-rules`, data, { headers: authHeader()});
  }

  static editRule(data: any, ruleId: any) {
    return http.put(`/cfadminvinadmin/api/v1/mapping-rules/rule-attributes/${ruleId}`, data, { headers: authHeader()});
  }

  static getInternalVins() {
    return http.get("/cfadminvinadmin/api/v1/manual-vin-pattern",{ headers: authHeader()});
  }

  static createInternalVin(data: any) {
    return http.post(`/cfadminvinadmin/api/v1/manual-vin-pattern`, data, { headers: authHeader()});
  }

  static updateInternalVin(data: any) {
    return http.put(`/cfadminvinadmin/api/v1/manual-vin-pattern`, data, { headers: authHeader()});
  }

  static deleteInternalVin(id: number) {
    return http.delete(`/cfadminvinadmin/api/v1/manual-vin-pattern?patternId=${id}`, { headers: authHeader()});
  }

  static updateFlag(data: any) {
    return http.put(`/cfadminvinadmin/api/v1/manual-vin-pattern/changeFlag`, data, { headers: authHeader()});
  }

  static getReleasePreprocessReport() {
    return http.get("/cfadminvinadmin/api/v1/release",{ headers: authHeader()});
  }

  static releaseVin(data: any) {
    return http.post(`/cfadminvinadmin/api/v1/release`, data, { headers: authHeader()});
  }

  static getRuleGroupIndex() {
    return http.get(`/cfadminvinadmin/api/v1/mapping-rules`, { headers: authHeader()});
  }

  static getRuleQueries(attribute : string, fordFlag : boolean) {
    return http.get(`/cfadminvinadmin/api/v1/mapping-rules/rules-query?attribute=${attribute}&fordFlag=${fordFlag}`, { headers: authHeader()});
  }

  static deleteRuleById(ruleId: any) {
    return http.delete(`/cfadminvinadmin/api/v1/mapping-rules/delete-rule-by-id/${ruleId}`, { headers: authHeader()});
  }

  static getRuleAttributesById(ruleId: any) {
    return http.get(`/cfadminvinadmin/api/v1/mapping-rules/rule-attributes?ruleId=${ruleId}`, { headers: authHeader()});
  }

  static getUnknownVinFragments(fromDate: Date) {
    const timestamp: string = fromDate.toISOString();
    return http.get(`/cfadminvinadmin/api/v1/error-vins?timestamp=${timestamp}`, { headers: authHeader()});
  }
}

export default VinImportService;